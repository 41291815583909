/**
 * Represents the ID of a country in the PandaTS Database.
 * @typedef {number} PandaTsCountryId
 * @property {1-250} The ID of the country.
 */
export type PandaTsCountryId =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23
  | 24
  | 25
  | 26
  | 27
  | 28
  | 29
  | 30
  | 31
  | 32
  | 33
  | 34
  | 35
  | 36
  | 37
  | 38
  | 39
  | 40
  | 41
  | 42
  | 43
  | 44
  | 45
  | 46
  | 47
  | 48
  | 49
  | 50
  | 51
  | 52
  | 53
  | 54
  | 55
  | 56
  | 57
  | 58
  | 59
  | 60
  | 61
  | 62
  | 63
  | 64
  | 65
  | 66
  | 67
  | 68
  | 69
  | 70
  | 71
  | 72
  | 73
  | 74
  | 75
  | 76
  | 77
  | 78
  | 79
  | 80
  | 81
  | 82
  | 83
  | 84
  | 85
  | 86
  | 87
  | 88
  | 89
  | 90
  | 91
  | 92
  | 93
  | 94
  | 95
  | 96
  | 97
  | 98
  | 99
  | 100
  | 101
  | 102
  | 103
  | 104
  | 105
  | 106
  | 107
  | 108
  | 109
  | 110
  | 111
  | 112
  | 113
  | 114
  | 115
  | 116
  | 117
  | 118
  | 119
  | 120
  | 121
  | 122
  | 123
  | 124
  | 125
  | 126
  | 127
  | 128
  | 129
  | 130
  | 131
  | 132
  | 133
  | 134
  | 135
  | 136
  | 137
  | 138
  | 139
  | 140
  | 141
  | 142
  | 143
  | 144
  | 145
  | 146
  | 147
  | 148
  | 149
  | 150
  | 151
  | 152
  | 153
  | 154
  | 155
  | 156
  | 157
  | 158
  | 159
  | 160
  | 161
  | 162
  | 163
  | 164
  | 165
  | 166
  | 167
  | 168
  | 169
  | 170
  | 171
  | 172
  | 173
  | 174
  | 175
  | 176
  | 177
  | 178
  | 179
  | 180
  | 181
  | 182
  | 183
  | 184
  | 185
  | 186
  | 187
  | 188
  | 189
  | 190
  | 191
  | 192
  | 193
  | 194
  | 195
  | 196
  | 197
  | 198
  | 199
  | 200
  | 201
  | 202
  | 203
  | 204
  | 205
  | 206
  | 207
  | 208
  | 209
  | 210
  | 211
  | 212
  | 213
  | 214
  | 215
  | 216
  | 217
  | 218
  | 219
  | 220
  | 221
  | 222
  | 223
  | 224
  | 225
  | 226
  | 227
  | 228
  | 229
  | 230
  | 231
  | 232
  | 233
  | 234
  | 235
  | 236
  | 237
  | 238
  | 239
  | 240
  | 241
  | 242
  | 243
  | 244
  | 245
  | 246
  | 247
  | 248
  | 249
  | 250

/**
 * Represents a two-letter country code according to ISO 3166-1 alpha-2 standard.
 * @example 'US' represents the United States of America.
 */
export type PandaTsCountryAlpha2 =
  | 'AF'
  | 'AX'
  | 'AL'
  | 'DZ'
  | 'AS'
  | 'AD'
  | 'AO'
  | 'AI'
  | 'AG'
  | 'AR'
  | 'AM'
  | 'AW'
  | 'AU'
  | 'AT'
  | 'AZ'
  | 'BS'
  | 'BH'
  | 'BD'
  | 'BB'
  | 'BY'
  | 'BE'
  | 'BZ'
  | 'BJ'
  | 'BM'
  | 'BT'
  | 'BO'
  | 'BQ'
  | 'BA'
  | 'BW'
  | 'BR'
  | 'IO'
  | 'VG'
  | 'BN'
  | 'BG'
  | 'BF'
  | 'BI'
  | 'KH'
  | 'CM'
  | 'CA'
  | 'CV'
  | 'KY'
  | 'CF'
  | 'TD'
  | 'CL'
  | 'CN'
  | 'CX'
  | 'CC'
  | 'CO'
  | 'KM'
  | 'CG'
  | 'CD'
  | 'CK'
  | 'CR'
  | 'HR'
  | 'CU'
  | 'CW'
  | 'CY'
  | 'CZ'
  | 'DK'
  | 'DJ'
  | 'DM'
  | 'DO'
  | 'TL'
  | 'EC'
  | 'EG'
  | 'SV'
  | 'GQ'
  | 'ER'
  | 'EE'
  | 'ET'
  | 'FO'
  | 'FK'
  | 'FJ'
  | 'FI'
  | 'FR'
  | 'GF'
  | 'PF'
  | 'TF'
  | 'GA'
  | 'GM'
  | 'GE'
  | 'DE'
  | 'GH'
  | 'GI'
  | 'GR'
  | 'GL'
  | 'GD'
  | 'GP'
  | 'GU'
  | 'GT'
  | 'GG'
  | 'GN'
  | 'GW'
  | 'GY'
  | 'HT'
  | 'VA'
  | 'HN'
  | 'HK'
  | 'HU'
  | 'IS'
  | 'IN'
  | 'ID'
  | 'IR'
  | 'IQ'
  | 'IE'
  | 'IM'
  | 'IL'
  | 'IT'
  | 'CI'
  | 'JM'
  | 'JP'
  | 'JE'
  | 'JO'
  | 'KZ'
  | 'KE'
  | 'KI'
  | 'KW'
  | 'KG'
  | 'LA'
  | 'LV'
  | 'LB'
  | 'LS'
  | 'LR'
  | 'LY'
  | 'LI'
  | 'LT'
  | 'LU'
  | 'MO'
  | 'MK'
  | 'MG'
  | 'MW'
  | 'MY'
  | 'MV'
  | 'ML'
  | 'MT'
  | 'MH'
  | 'MQ'
  | 'MR'
  | 'MU'
  | 'YT'
  | 'MX'
  | 'FM'
  | 'MD'
  | 'MC'
  | 'MN'
  | 'ME'
  | 'MS'
  | 'MA'
  | 'MZ'
  | 'MM'
  | 'NA'
  | 'NR'
  | 'NP'
  | 'AN'
  | 'NL'
  | 'NC'
  | 'NZ'
  | 'NI'
  | 'NE'
  | 'NG'
  | 'NU'
  | 'NF'
  | 'KP'
  | 'MP'
  | 'NO'
  | 'OM'
  | 'PK'
  | 'PW'
  | 'PS'
  | 'PA'
  | 'PG'
  | 'PY'
  | 'PE'
  | 'PH'
  | 'PN'
  | 'PL'
  | 'PT'
  | 'PR'
  | 'QA'
  | 'RO'
  | 'RU'
  | 'RW'
  | 'RE'
  | 'BQ'
  | 'BL'
  | 'KN'
  | 'SH'
  | 'LC'
  | 'MF'
  | 'PM'
  | 'VC'
  | 'WS'
  | 'SM'
  | 'ST'
  | 'SA'
  | 'SN'
  | 'RS'
  | 'SC'
  | 'SL'
  | 'SG'
  | 'BQ'
  | 'SX'
  | 'SK'
  | 'SI'
  | 'SB'
  | 'SO'
  | 'SO'
  | 'ZA'
  | 'GS'
  | 'KR'
  | 'SS'
  | 'ES'
  | 'LK'
  | 'SD'
  | 'SR'
  | 'SZ'
  | 'SE'
  | 'CH'
  | 'SY'
  | 'TW'
  | 'TJ'
  | 'TZ'
  | 'TH'
  | 'TG'
  | 'TK'
  | 'TO'
  | 'TT'
  | 'TN'
  | 'TR'
  | 'TM'
  | 'TC'
  | 'TV'
  | 'UG'
  | 'UA'
  | 'AE'
  | 'GB'
  | 'US'
  | 'VI'
  | 'UY'
  | 'UZ'
  | 'VU'
  | 'VE'
  | 'VN'
  | 'WF'
  | 'EH'
  | 'YE'
  | 'ZM'
  | 'ZW'
  | 'KN'
  | 'XK'

/**
 * Represents a list of ISO 3166-1 alpha-3 country codes.
 * @example 'USA' represents the United States of America.
 */
export type PandaTsCountryAlpha3 =
  | 'AFG'
  | 'ALA'
  | 'ALB'
  | 'DZA'
  | 'ASM'
  | 'AND'
  | 'AGO'
  | 'AIA'
  | 'ATG'
  | 'ARG'
  | 'ARM'
  | 'ABW'
  | 'AUS'
  | 'AUT'
  | 'AZE'
  | 'BHS'
  | 'BHR'
  | 'BGD'
  | 'BRB'
  | 'BLR'
  | 'BEL'
  | 'BLZ'
  | 'BEN'
  | 'BMU'
  | 'BTN'
  | 'BOL'
  | 'BES'
  | 'BIH'
  | 'BWA'
  | 'BRA'
  | 'IOT'
  | 'VGB'
  | 'BRN'
  | 'BGR'
  | 'BFA'
  | 'BDI'
  | 'KHM'
  | 'CMR'
  | 'CAN'
  | 'CPV'
  | 'CYM'
  | 'CAF'
  | 'TCD'
  | 'CHL'
  | 'CHN'
  | 'CXR'
  | 'CCK'
  | 'COL'
  | 'COM'
  | 'COG'
  | 'ZAR'
  | 'COK'
  | 'CRI'
  | 'HRV'
  | 'CUB'
  | 'CUW'
  | 'CYP'
  | 'CZE'
  | 'DNK'
  | 'DJI'
  | 'DMA'
  | 'DOM'
  | 'TLS'
  | 'ECU'
  | 'EGY'
  | 'SLV'
  | 'GNQ'
  | 'ERI'
  | 'EST'
  | 'ETH'
  | 'FRO'
  | 'FLK'
  | 'FJI'
  | 'FIN'
  | 'FRA'
  | 'GUF'
  | 'PYF'
  | 'ATF'
  | 'GAB'
  | 'GMB'
  | 'GEO'
  | 'DEU'
  | 'GHA'
  | 'GIB'
  | 'GRC'
  | 'GRL'
  | 'GRD'
  | 'GLP'
  | 'GUM'
  | 'GTM'
  | 'GGY'
  | 'GIN'
  | 'GNB'
  | 'GUY'
  | 'HTI'
  | 'VAT'
  | 'HND'
  | 'HKG'
  | 'HUN'
  | 'ISL'
  | 'IND'
  | 'IDN'
  | 'IRN'
  | 'IRQ'
  | 'IRL'
  | 'IMN'
  | 'ISR'
  | 'ITA'
  | 'CIV'
  | 'JAM'
  | 'JPN'
  | 'JEY'
  | 'JOR'
  | 'KAZ'
  | 'KEN'
  | 'KIR'
  | 'KWT'
  | 'KGZ'
  | 'LAO'
  | 'LVA'
  | 'LBN'
  | 'LSO'
  | 'LBR'
  | 'LBY'
  | 'LIE'
  | 'LTU'
  | 'LUX'
  | 'MAC'
  | 'MKD'
  | 'MDG'
  | 'MWI'
  | 'MYS'
  | 'MDV'
  | 'MLI'
  | 'MLT'
  | 'MHL'
  | 'MTQ'
  | 'MRT'
  | 'MUS'
  | 'MYT'
  | 'MEX'
  | 'FSM'
  | 'MDA'
  | 'MCO'
  | 'MNG'
  | 'MNE'
  | 'MSR'
  | 'MAR'
  | 'MOZ'
  | 'MMR'
  | 'NAM'
  | 'NRU'
  | 'NPL'
  | 'ANT'
  | 'NLD'
  | 'NCL'
  | 'NZL'
  | 'NIC'
  | 'NER'
  | 'NGA'
  | 'NIU'
  | 'NFK'
  | 'PRK'
  | 'MNP'
  | 'NOR'
  | 'OMN'
  | 'PAK'
  | 'PLW'
  | 'PSE'
  | 'PAN'
  | 'PNG'
  | 'PRY'
  | 'PER'
  | 'PHL'
  | 'PCN'
  | 'POL'
  | 'PRT'
  | 'PRI'
  | 'QAT'
  | 'ROU'
  | 'RUS'
  | 'RWA'
  | 'REU'
  | 'BES'
  | 'BLM'
  | 'KNA'
  | 'SHN'
  | 'LCA'
  | 'MAF'
  | 'SPM'
  | 'VCT'
  | 'WSM'
  | 'SMR'
  | 'STP'
  | 'SAU'
  | 'SEN'
  | 'SRB'
  | 'SYC'
  | 'SLE'
  | 'SGP'
  | 'BES'
  | 'SXM'
  | 'SVK'
  | 'SVN'
  | 'SLB'
  | 'SOM'
  | 'SOM'
  | 'ZAF'
  | 'SGS'
  | 'KOR'
  | 'SSD'
  | 'ESP'
  | 'LKA'
  | 'SDN'
  | 'SUR'
  | 'SWZ'
  | 'SWE'
  | 'CHE'
  | 'SYR'
  | 'TWN'
  | 'TJK'
  | 'TZA'
  | 'THA'
  | 'TGO'
  | 'TKL'
  | 'TON'
  | 'TTO'
  | 'TUN'
  | 'TUR'
  | 'TKM'
  | 'TCA'
  | 'TUV'
  | 'UGA'
  | 'UKR'
  | 'ARE'
  | 'GBR'
  | 'USA'
  | 'VIR'
  | 'URY'
  | 'UZB'
  | 'VUT'
  | 'VEN'
  | 'VNM'
  | 'WLF'
  | 'ESH'
  | 'YEM'
  | 'ZMB'
  | 'ZWE'
  | 'KNA'
  | 'XKX'

/**
 * Represents the names of all countries in the world known by PandaTS.
 */
export type PandaTsCountryName =
  | 'Afghanistan'
  | 'Åland Islands'
  | 'Albania'
  | 'Algeria'
  | 'American Samoa'
  | 'Andorra'
  | 'Angola'
  | 'Anguilla'
  | 'Antigua & Barbuda'
  | 'Argentina'
  | 'Armenia'
  | 'Aruba'
  | 'Australia'
  | 'Austria'
  | 'Azerbaijan'
  | 'Bahamas'
  | 'Bahrain'
  | 'Bangladesh'
  | 'Barbados'
  | 'Belarus'
  | 'Belgium'
  | 'Belize'
  | 'Benin'
  | 'Bermuda'
  | 'Bhutan'
  | 'Bolivia'
  | 'Bonaire'
  | 'Bosnia-Herzegovina'
  | 'Botswana'
  | 'Brazil'
  | 'British Indian Ocean Territory'
  | 'British Virgin Islands'
  | 'Brunei Darussalam'
  | 'Bulgaria'
  | 'Burkina Faso'
  | 'Burundi'
  | 'Cambodia'
  | 'Cameroon'
  | 'Canada'
  | 'Cape Verde Islands'
  | 'Cayman Islands'
  | 'Central African Republic'
  | 'Chad'
  | 'Chile'
  | 'China'
  | 'Christmas Island'
  | 'Cocos (Keeling) Islands'
  | 'Colombia'
  | 'Comoros'
  | 'Congo (Brazzaville)'
  | 'Congo (Kinshasa)'
  | 'Cook Islands'
  | 'Costa Rica'
  | 'Croatia'
  | 'Cuba'
  | 'Curaçao'
  | 'Cyprus'
  | 'Czech Republic'
  | 'Denmark'
  | 'Djibouti'
  | 'Dominica'
  | 'Dominican Republic'
  | 'East Timor'
  | 'Ecuador'
  | 'Egypt'
  | 'El Salvador'
  | 'Equatorial Guinea'
  | 'Eritrea'
  | 'Estonia'
  | 'Ethiopia'
  | 'Faroe Islands'
  | 'Falkland Islands'
  | 'Fiji'
  | 'Finland'
  | 'France'
  | 'French Guiana'
  | 'French Polynesia'
  | 'French Southern Territories'
  | 'Gabon'
  | 'Gambia'
  | 'Georgia'
  | 'Germany'
  | 'Ghana'
  | 'Gibraltar'
  | 'Greece'
  | 'Greenland'
  | 'Grenada'
  | 'Guadeloupe'
  | 'Guam'
  | 'Guatemala'
  | 'Guernsey'
  | 'Guinea'
  | 'Guinea-Bissau'
  | 'Guyana'
  | 'Haiti'
  | 'Holy See'
  | 'Honduras'
  | 'Hong Kong'
  | 'Hungary'
  | 'Iceland'
  | 'India'
  | 'Indonesia'
  | 'Iran'
  | 'Iraq'
  | 'Ireland'
  | 'Isle of Man'
  | 'Israel'
  | 'Italy'
  | 'Ivory Coast'
  | 'Jamaica'
  | 'Japan'
  | 'Jersey'
  | 'Jordan'
  | 'Kazakhstan'
  | 'Kenya'
  | 'Kiribati'
  | 'Kuwait'
  | 'Kyrgyzstan'
  | 'Laos'
  | 'Latvia'
  | 'Lebanon'
  | 'Lesotho'
  | 'Liberia'
  | 'Libya'
  | 'Liechtenstein'
  | 'Lithuania'
  | 'Luxembourg'
  | 'Macau'
  | 'Macedonia'
  | 'Madagascar'
  | 'Malawi'
  | 'Malaysia'
  | 'Maldives'
  | 'Mali'
  | 'Malta'
  | 'Marshall Islands'
  | 'Martinique'
  | 'Mauritania'
  | 'Mauritius'
  | 'Mayotte'
  | 'Mexico'
  | 'Micronesia'
  | 'Moldova'
  | 'Monaco'
  | 'Mongolia'
  | 'Montenegro'
  | 'Montserrat'
  | 'Morocco'
  | 'Mozambique'
  | 'Myanmar'
  | 'Namibia'
  | 'Nauru'
  | 'Nepal'
  | 'Netherlands Antilles'
  | 'Netherlands'
  | 'New Caledonia'
  | 'New Zealand'
  | 'Nicaragua'
  | 'Niger'
  | 'Nigeria'
  | 'Niue'
  | 'Norfolk Island'
  | 'North Korea'
  | 'Northern Mariana Islands'
  | 'Norway'
  | 'Oman'
  | 'Pakistan'
  | 'Palau'
  | 'Palestinian Territories'
  | 'Panama'
  | 'Papua New Guinea'
  | 'Paraguay'
  | 'Peru'
  | 'Philippines'
  | 'Pitcairn Islands'
  | 'Poland'
  | 'Portugal'
  | 'Puerto Rico'
  | 'Qatar'
  | 'Romania'
  | 'Russia'
  | 'Rwanda'
  | 'Réunion'
  | 'Saba'
  | 'Saint Barthélemy'
  | 'Saint Christopher & Nevis'
  | 'Saint Helena'
  | 'Saint Lucia'
  | 'Saint Martin'
  | 'Saint Pierre & Miquelon'
  | 'Saint Vincent & The Grenadines'
  | 'Samoa'
  | 'San Marino'
  | 'Sao Tome & Principe'
  | 'Saudi Arabia'
  | 'Senegal'
  | 'Serbia'
  | 'Seychelles'
  | 'Sierra Leone'
  | 'Singapore'
  | 'Sint Eustatius'
  | 'Sint Maarten'
  | 'Slovakia'
  | 'Slovenia'
  | 'Solomon Islands'
  | 'Somalia'
  | 'Somaliland'
  | 'South Africa'
  | 'South Georgia & The South Sandwish Islands'
  | 'South Korea'
  | 'South Sudan'
  | 'Spain'
  | 'Sri Lanka'
  | 'Sudan'
  | 'Suriname'
  | 'Eswatini'
  | 'Sweden'
  | 'Switzerland'
  | 'Syria'
  | 'Taiwan'
  | 'Tajikistan'
  | 'Tanzania'
  | 'Thailand'
  | 'Togo'
  | 'Tokelau'
  | 'Tonga '
  | 'Trinidad & Tobago'
  | 'Tunisia'
  | 'Turkey'
  | 'Turkmenistan'
  | 'Turks & Caicos Islands'
  | 'Tuvalu'
  | 'Uganda'
  | 'Ukraine'
  | 'United Arab Emirates'
  | 'United Kingdom'
  | 'United States'
  | 'United States Virgin Islands'
  | 'Uruguay'
  | 'Uzbekistan'
  | 'Vanuatu'
  | 'Venezuela'
  | 'Vietnam'
  | 'Wallis & Futuna'
  | 'Western Sahara'
  | 'Yemen'
  | 'Zambia'
  | 'Zimbabwe'
  | 'Saint Kitts'
  | 'Kosovo'
/**
 * Represents a country supported by PandaTS.
 * @example { id: 1, iso: '004', alpha2: 'AF', alpha3: 'AFG', name: 'Afghanistan', phonePrefix: '+93' }
 * @example { id: 2, iso: '008', alpha2: 'AL', alpha3: 'ALB', name: 'Albania', phonePrefix: '+355' }
 */
export interface PandaTsCountry {
  id: PandaTsCountryId
  alpha2: PandaTsCountryAlpha2
  alpha3: PandaTsCountryAlpha3
  name: PandaTsCountryName
}

/**
 * A list of all countries supported by PandaTS.
 */
export const PandaTsCountries: Array<PandaTsCountry> = [
  {
    id: 1,
    name: 'Afghanistan',
    alpha2: 'AF',
    alpha3: 'AFG',
  },
  {
    id: 2,
    name: 'Åland Islands',
    alpha2: 'AX',
    alpha3: 'ALA',
  },
  {
    id: 3,
    name: 'Albania',
    alpha2: 'AL',
    alpha3: 'ALB',
  },
  {
    id: 4,
    name: 'Algeria',
    alpha2: 'DZ',
    alpha3: 'DZA',
  },
  {
    id: 5,
    name: 'American Samoa',
    alpha2: 'AS',
    alpha3: 'ASM',
  },
  {
    id: 6,
    name: 'Andorra',
    alpha2: 'AD',
    alpha3: 'AND',
  },
  {
    id: 7,
    name: 'Angola',
    alpha2: 'AO',
    alpha3: 'AGO',
  },
  {
    id: 8,
    name: 'Anguilla',
    alpha2: 'AI',
    alpha3: 'AIA',
  },
  {
    id: 9,
    name: 'Antigua & Barbuda',
    alpha2: 'AG',
    alpha3: 'ATG',
  },
  {
    id: 10,
    name: 'Argentina',
    alpha2: 'AR',
    alpha3: 'ARG',
  },
  {
    id: 11,
    name: 'Armenia',
    alpha2: 'AM',
    alpha3: 'ARM',
  },
  {
    id: 12,
    name: 'Aruba',
    alpha2: 'AW',
    alpha3: 'ABW',
  },
  {
    id: 13,
    name: 'Australia',
    alpha2: 'AU',
    alpha3: 'AUS',
  },
  {
    id: 14,
    name: 'Austria',
    alpha2: 'AT',
    alpha3: 'AUT',
  },
  {
    id: 15,
    name: 'Azerbaijan',
    alpha2: 'AZ',
    alpha3: 'AZE',
  },
  {
    id: 16,
    name: 'Bahamas',
    alpha2: 'BS',
    alpha3: 'BHS',
  },
  {
    id: 17,
    name: 'Bahrain',
    alpha2: 'BH',
    alpha3: 'BHR',
  },
  {
    id: 18,
    name: 'Bangladesh',
    alpha2: 'BD',
    alpha3: 'BGD',
  },
  {
    id: 19,
    name: 'Barbados',
    alpha2: 'BB',
    alpha3: 'BRB',
  },
  {
    id: 20,
    name: 'Belarus',
    alpha2: 'BY',
    alpha3: 'BLR',
  },
  {
    id: 21,
    name: 'Belgium',
    alpha2: 'BE',
    alpha3: 'BEL',
  },
  {
    id: 22,
    name: 'Belize',
    alpha2: 'BZ',
    alpha3: 'BLZ',
  },
  {
    id: 23,
    name: 'Benin',
    alpha2: 'BJ',
    alpha3: 'BEN',
  },
  {
    id: 24,
    name: 'Bermuda',
    alpha2: 'BM',
    alpha3: 'BMU',
  },
  {
    id: 25,
    name: 'Bhutan',
    alpha2: 'BT',
    alpha3: 'BTN',
  },
  {
    id: 26,
    name: 'Bolivia',
    alpha2: 'BO',
    alpha3: 'BOL',
  },
  {
    id: 27,
    name: 'Bonaire',
    alpha2: 'BQ',
    alpha3: 'BES',
  },
  {
    id: 28,
    name: 'Bosnia-Herzegovina',
    alpha2: 'BA',
    alpha3: 'BIH',
  },
  {
    id: 29,
    name: 'Botswana',
    alpha2: 'BW',
    alpha3: 'BWA',
  },
  {
    id: 30,
    name: 'Brazil',
    alpha2: 'BR',
    alpha3: 'BRA',
  },
  {
    id: 31,
    name: 'British Indian Ocean Territory',
    alpha2: 'IO',
    alpha3: 'IOT',
  },
  {
    id: 32,
    name: 'British Virgin Islands',
    alpha2: 'VG',
    alpha3: 'VGB',
  },
  {
    id: 33,
    name: 'Brunei Darussalam',
    alpha2: 'BN',
    alpha3: 'BRN',
  },
  {
    id: 34,
    name: 'Bulgaria',
    alpha2: 'BG',
    alpha3: 'BGR',
  },
  {
    id: 35,
    name: 'Burkina Faso',
    alpha2: 'BF',
    alpha3: 'BFA',
  },
  {
    id: 36,
    name: 'Burundi',
    alpha2: 'BI',
    alpha3: 'BDI',
  },
  {
    id: 37,
    name: 'Cambodia',
    alpha2: 'KH',
    alpha3: 'KHM',
  },
  {
    id: 38,
    name: 'Cameroon',
    alpha2: 'CM',
    alpha3: 'CMR',
  },
  {
    id: 39,
    name: 'Canada',
    alpha2: 'CA',
    alpha3: 'CAN',
  },
  {
    id: 40,
    name: 'Cape Verde Islands',
    alpha2: 'CV',
    alpha3: 'CPV',
  },
  {
    id: 41,
    name: 'Cayman Islands',
    alpha2: 'KY',
    alpha3: 'CYM',
  },
  {
    id: 42,
    name: 'Central African Republic',
    alpha2: 'CF',
    alpha3: 'CAF',
  },
  {
    id: 43,
    name: 'Chad',
    alpha2: 'TD',
    alpha3: 'TCD',
  },
  {
    id: 44,
    name: 'Chile',
    alpha2: 'CL',
    alpha3: 'CHL',
  },
  {
    id: 45,
    name: 'China',
    alpha2: 'CN',
    alpha3: 'CHN',
  },
  {
    id: 46,
    name: 'Christmas Island',
    alpha2: 'CX',
    alpha3: 'CXR',
  },
  {
    id: 47,
    name: 'Cocos (Keeling) Islands',
    alpha2: 'CC',
    alpha3: 'CCK',
  },
  {
    id: 48,
    name: 'Colombia',
    alpha2: 'CO',
    alpha3: 'COL',
  },
  {
    id: 49,
    name: 'Comoros',
    alpha2: 'KM',
    alpha3: 'COM',
  },
  {
    id: 50,
    name: 'Congo (Brazzaville)',
    alpha2: 'CG',
    alpha3: 'COG',
  },
  {
    id: 51,
    name: 'Congo (Kinshasa)',
    alpha2: 'CD',
    alpha3: 'ZAR',
  },
  {
    id: 52,
    name: 'Cook Islands',
    alpha2: 'CK',
    alpha3: 'COK',
  },
  {
    id: 53,
    name: 'Costa Rica',
    alpha2: 'CR',
    alpha3: 'CRI',
  },
  {
    id: 54,
    name: 'Croatia',
    alpha2: 'HR',
    alpha3: 'HRV',
  },
  {
    id: 55,
    name: 'Cuba',
    alpha2: 'CU',
    alpha3: 'CUB',
  },
  {
    id: 56,
    name: 'Curaçao',
    alpha2: 'CW',
    alpha3: 'CUW',
  },
  {
    id: 57,
    name: 'Cyprus',
    alpha2: 'CY',
    alpha3: 'CYP',
  },
  {
    id: 58,
    name: 'Czech Republic',
    alpha2: 'CZ',
    alpha3: 'CZE',
  },
  {
    id: 59,
    name: 'Denmark',
    alpha2: 'DK',
    alpha3: 'DNK',
  },
  {
    id: 60,
    name: 'Djibouti',
    alpha2: 'DJ',
    alpha3: 'DJI',
  },
  {
    id: 61,
    name: 'Dominica',
    alpha2: 'DM',
    alpha3: 'DMA',
  },
  {
    id: 62,
    name: 'Dominican Republic',
    alpha2: 'DO',
    alpha3: 'DOM',
  },
  {
    id: 63,
    name: 'East Timor',
    alpha2: 'TL',
    alpha3: 'TLS',
  },
  {
    id: 64,
    name: 'Ecuador',
    alpha2: 'EC',
    alpha3: 'ECU',
  },
  {
    id: 65,
    name: 'Egypt',
    alpha2: 'EG',
    alpha3: 'EGY',
  },
  {
    id: 66,
    name: 'El Salvador',
    alpha2: 'SV',
    alpha3: 'SLV',
  },
  {
    id: 67,
    name: 'Equatorial Guinea',
    alpha2: 'GQ',
    alpha3: 'GNQ',
  },
  {
    id: 68,
    name: 'Eritrea',
    alpha2: 'ER',
    alpha3: 'ERI',
  },
  {
    id: 69,
    name: 'Estonia',
    alpha2: 'EE',
    alpha3: 'EST',
  },
  {
    id: 70,
    name: 'Ethiopia',
    alpha2: 'ET',
    alpha3: 'ETH',
  },
  {
    id: 71,
    name: 'Faroe Islands',
    alpha2: 'FO',
    alpha3: 'FRO',
  },
  {
    id: 72,
    name: 'Falkland Islands',
    alpha2: 'FK',
    alpha3: 'FLK',
  },
  {
    id: 73,
    name: 'Fiji',
    alpha2: 'FJ',
    alpha3: 'FJI',
  },
  {
    id: 74,
    name: 'Finland',
    alpha2: 'FI',
    alpha3: 'FIN',
  },
  {
    id: 75,
    name: 'France',
    alpha2: 'FR',
    alpha3: 'FRA',
  },
  {
    id: 76,
    name: 'French Guiana',
    alpha2: 'GF',
    alpha3: 'GUF',
  },
  {
    id: 77,
    name: 'French Polynesia',
    alpha2: 'PF',
    alpha3: 'PYF',
  },
  {
    id: 78,
    name: 'French Southern Territories',
    alpha2: 'TF',
    alpha3: 'ATF',
  },
  {
    id: 79,
    name: 'Gabon',
    alpha2: 'GA',
    alpha3: 'GAB',
  },
  {
    id: 80,
    name: 'Gambia',
    alpha2: 'GM',
    alpha3: 'GMB',
  },
  {
    id: 81,
    name: 'Georgia',
    alpha2: 'GE',
    alpha3: 'GEO',
  },
  {
    id: 82,
    name: 'Germany',
    alpha2: 'DE',
    alpha3: 'DEU',
  },
  {
    id: 83,
    name: 'Ghana',
    alpha2: 'GH',
    alpha3: 'GHA',
  },
  {
    id: 84,
    name: 'Gibraltar',
    alpha2: 'GI',
    alpha3: 'GIB',
  },
  {
    id: 85,
    name: 'Greece',
    alpha2: 'GR',
    alpha3: 'GRC',
  },
  {
    id: 86,
    name: 'Greenland',
    alpha2: 'GL',
    alpha3: 'GRL',
  },
  {
    id: 87,
    name: 'Grenada',
    alpha2: 'GD',
    alpha3: 'GRD',
  },
  {
    id: 88,
    name: 'Guadeloupe',
    alpha2: 'GP',
    alpha3: 'GLP',
  },
  {
    id: 89,
    name: 'Guam',
    alpha2: 'GU',
    alpha3: 'GUM',
  },
  {
    id: 90,
    name: 'Guatemala',
    alpha2: 'GT',
    alpha3: 'GTM',
  },
  {
    id: 91,
    name: 'Guernsey',
    alpha2: 'GG',
    alpha3: 'GGY',
  },
  {
    id: 92,
    name: 'Guinea',
    alpha2: 'GN',
    alpha3: 'GIN',
  },
  {
    id: 93,
    name: 'Guinea-Bissau',
    alpha2: 'GW',
    alpha3: 'GNB',
  },
  {
    id: 94,
    name: 'Guyana',
    alpha2: 'GY',
    alpha3: 'GUY',
  },
  {
    id: 95,
    name: 'Haiti',
    alpha2: 'HT',
    alpha3: 'HTI',
  },
  {
    id: 96,
    name: 'Holy See',
    alpha2: 'VA',
    alpha3: 'VAT',
  },
  {
    id: 97,
    name: 'Honduras',
    alpha2: 'HN',
    alpha3: 'HND',
  },
  {
    id: 98,
    name: 'Hong Kong',
    alpha2: 'HK',
    alpha3: 'HKG',
  },
  {
    id: 99,
    name: 'Hungary',
    alpha2: 'HU',
    alpha3: 'HUN',
  },
  {
    id: 100,
    name: 'Iceland',
    alpha2: 'IS',
    alpha3: 'ISL',
  },
  {
    id: 101,
    name: 'India',
    alpha2: 'IN',
    alpha3: 'IND',
  },
  {
    id: 102,
    name: 'Indonesia',
    alpha2: 'ID',
    alpha3: 'IDN',
  },
  {
    id: 103,
    name: 'Iran',
    alpha2: 'IR',
    alpha3: 'IRN',
  },
  {
    id: 104,
    name: 'Iraq',
    alpha2: 'IQ',
    alpha3: 'IRQ',
  },
  {
    id: 105,
    name: 'Ireland',
    alpha2: 'IE',
    alpha3: 'IRL',
  },
  {
    id: 106,
    name: 'Isle of Man',
    alpha2: 'IM',
    alpha3: 'IMN',
  },
  {
    id: 107,
    name: 'Israel',
    alpha2: 'IL',
    alpha3: 'ISR',
  },
  {
    id: 108,
    name: 'Italy',
    alpha2: 'IT',
    alpha3: 'ITA',
  },
  {
    id: 109,
    name: 'Ivory Coast',
    alpha2: 'CI',
    alpha3: 'CIV',
  },
  {
    id: 110,
    name: 'Jamaica',
    alpha2: 'JM',
    alpha3: 'JAM',
  },
  {
    id: 111,
    name: 'Japan',
    alpha2: 'JP',
    alpha3: 'JPN',
  },
  {
    id: 112,
    name: 'Jersey',
    alpha2: 'JE',
    alpha3: 'JEY',
  },
  {
    id: 113,
    name: 'Jordan',
    alpha2: 'JO',
    alpha3: 'JOR',
  },
  {
    id: 114,
    name: 'Kazakhstan',
    alpha2: 'KZ',
    alpha3: 'KAZ',
  },
  {
    id: 115,
    name: 'Kenya',
    alpha2: 'KE',
    alpha3: 'KEN',
  },
  {
    id: 116,
    name: 'Kiribati',
    alpha2: 'KI',
    alpha3: 'KIR',
  },
  {
    id: 117,
    name: 'Kuwait',
    alpha2: 'KW',
    alpha3: 'KWT',
  },
  {
    id: 118,
    name: 'Kyrgyzstan',
    alpha2: 'KG',
    alpha3: 'KGZ',
  },
  {
    id: 119,
    name: 'Laos',
    alpha2: 'LA',
    alpha3: 'LAO',
  },
  {
    id: 120,
    name: 'Latvia',
    alpha2: 'LV',
    alpha3: 'LVA',
  },
  {
    id: 121,
    name: 'Lebanon',
    alpha2: 'LB',
    alpha3: 'LBN',
  },
  {
    id: 122,
    name: 'Lesotho',
    alpha2: 'LS',
    alpha3: 'LSO',
  },
  {
    id: 123,
    name: 'Liberia',
    alpha2: 'LR',
    alpha3: 'LBR',
  },
  {
    id: 124,
    name: 'Libya',
    alpha2: 'LY',
    alpha3: 'LBY',
  },
  {
    id: 125,
    name: 'Liechtenstein',
    alpha2: 'LI',
    alpha3: 'LIE',
  },
  {
    id: 126,
    name: 'Lithuania',
    alpha2: 'LT',
    alpha3: 'LTU',
  },
  {
    id: 127,
    name: 'Luxembourg',
    alpha2: 'LU',
    alpha3: 'LUX',
  },
  {
    id: 128,
    name: 'Macau',
    alpha2: 'MO',
    alpha3: 'MAC',
  },
  {
    id: 129,
    name: 'Macedonia',
    alpha2: 'MK',
    alpha3: 'MKD',
  },
  {
    id: 130,
    name: 'Madagascar',
    alpha2: 'MG',
    alpha3: 'MDG',
  },
  {
    id: 131,
    name: 'Malawi',
    alpha2: 'MW',
    alpha3: 'MWI',
  },
  {
    id: 132,
    name: 'Malaysia',
    alpha2: 'MY',
    alpha3: 'MYS',
  },
  {
    id: 133,
    name: 'Maldives',
    alpha2: 'MV',
    alpha3: 'MDV',
  },
  {
    id: 134,
    name: 'Mali',
    alpha2: 'ML',
    alpha3: 'MLI',
  },
  {
    id: 135,
    name: 'Malta',
    alpha2: 'MT',
    alpha3: 'MLT',
  },
  {
    id: 136,
    name: 'Marshall Islands',
    alpha2: 'MH',
    alpha3: 'MHL',
  },
  {
    id: 137,
    name: 'Martinique',
    alpha2: 'MQ',
    alpha3: 'MTQ',
  },
  {
    id: 138,
    name: 'Mauritania',
    alpha2: 'MR',
    alpha3: 'MRT',
  },
  {
    id: 139,
    name: 'Mauritius',
    alpha2: 'MU',
    alpha3: 'MUS',
  },
  {
    id: 140,
    name: 'Mayotte',
    alpha2: 'YT',
    alpha3: 'MYT',
  },
  {
    id: 141,
    name: 'Mexico',
    alpha2: 'MX',
    alpha3: 'MEX',
  },
  {
    id: 142,
    name: 'Micronesia',
    alpha2: 'FM',
    alpha3: 'FSM',
  },
  {
    id: 143,
    name: 'Moldova',
    alpha2: 'MD',
    alpha3: 'MDA',
  },
  {
    id: 144,
    name: 'Monaco',
    alpha2: 'MC',
    alpha3: 'MCO',
  },
  {
    id: 145,
    name: 'Mongolia',
    alpha2: 'MN',
    alpha3: 'MNG',
  },
  {
    id: 146,
    name: 'Montenegro',
    alpha2: 'ME',
    alpha3: 'MNE',
  },
  {
    id: 147,
    name: 'Montserrat',
    alpha2: 'MS',
    alpha3: 'MSR',
  },
  {
    id: 148,
    name: 'Morocco',
    alpha2: 'MA',
    alpha3: 'MAR',
  },
  {
    id: 149,
    name: 'Mozambique',
    alpha2: 'MZ',
    alpha3: 'MOZ',
  },
  {
    id: 150,
    name: 'Myanmar',
    alpha2: 'MM',
    alpha3: 'MMR',
  },
  {
    id: 151,
    name: 'Namibia',
    alpha2: 'NA',
    alpha3: 'NAM',
  },
  {
    id: 152,
    name: 'Nauru',
    alpha2: 'NR',
    alpha3: 'NRU',
  },
  {
    id: 153,
    name: 'Nepal',
    alpha2: 'NP',
    alpha3: 'NPL',
  },
  {
    id: 154,
    name: 'Netherlands Antilles',
    alpha2: 'AN',
    alpha3: 'ANT',
  },
  {
    id: 155,
    name: 'Netherlands',
    alpha2: 'NL',
    alpha3: 'NLD',
  },
  {
    id: 156,
    name: 'New Caledonia',
    alpha2: 'NC',
    alpha3: 'NCL',
  },
  {
    id: 157,
    name: 'New Zealand',
    alpha2: 'NZ',
    alpha3: 'NZL',
  },
  {
    id: 158,
    name: 'Nicaragua',
    alpha2: 'NI',
    alpha3: 'NIC',
  },
  {
    id: 159,
    name: 'Niger',
    alpha2: 'NE',
    alpha3: 'NER',
  },
  {
    id: 160,
    name: 'Nigeria',
    alpha2: 'NG',
    alpha3: 'NGA',
  },
  {
    id: 161,
    name: 'Niue',
    alpha2: 'NU',
    alpha3: 'NIU',
  },
  {
    id: 162,
    name: 'Norfolk Island',
    alpha2: 'NF',
    alpha3: 'NFK',
  },
  {
    id: 163,
    name: 'North Korea',
    alpha2: 'KP',
    alpha3: 'PRK',
  },
  {
    id: 164,
    name: 'Northern Mariana Islands',
    alpha2: 'MP',
    alpha3: 'MNP',
  },
  {
    id: 165,
    name: 'Norway',
    alpha2: 'NO',
    alpha3: 'NOR',
  },
  {
    id: 166,
    name: 'Oman',
    alpha2: 'OM',
    alpha3: 'OMN',
  },
  {
    id: 167,
    name: 'Pakistan',
    alpha2: 'PK',
    alpha3: 'PAK',
  },
  {
    id: 168,
    name: 'Palau',
    alpha2: 'PW',
    alpha3: 'PLW',
  },
  {
    id: 169,
    name: 'Palestinian Territories',
    alpha2: 'PS',
    alpha3: 'PSE',
  },
  {
    id: 170,
    name: 'Panama',
    alpha2: 'PA',
    alpha3: 'PAN',
  },
  {
    id: 171,
    name: 'Papua New Guinea',
    alpha2: 'PG',
    alpha3: 'PNG',
  },
  {
    id: 172,
    name: 'Paraguay',
    alpha2: 'PY',
    alpha3: 'PRY',
  },
  {
    id: 173,
    name: 'Peru',
    alpha2: 'PE',
    alpha3: 'PER',
  },
  {
    id: 174,
    name: 'Philippines',
    alpha2: 'PH',
    alpha3: 'PHL',
  },
  {
    id: 175,
    name: 'Pitcairn Islands',
    alpha2: 'PN',
    alpha3: 'PCN',
  },
  {
    id: 176,
    name: 'Poland',
    alpha2: 'PL',
    alpha3: 'POL',
  },
  {
    id: 177,
    name: 'Portugal',
    alpha2: 'PT',
    alpha3: 'PRT',
  },
  {
    id: 178,
    name: 'Puerto Rico',
    alpha2: 'PR',
    alpha3: 'PRI',
  },
  {
    id: 179,
    name: 'Qatar',
    alpha2: 'QA',
    alpha3: 'QAT',
  },
  {
    id: 180,
    name: 'Romania',
    alpha2: 'RO',
    alpha3: 'ROU',
  },
  {
    id: 181,
    name: 'Russia',
    alpha2: 'RU',
    alpha3: 'RUS',
  },
  {
    id: 182,
    name: 'Rwanda',
    alpha2: 'RW',
    alpha3: 'RWA',
  },
  {
    id: 183,
    name: 'Réunion',
    alpha2: 'RE',
    alpha3: 'REU',
  },
  {
    id: 184,
    name: 'Saba',
    alpha2: 'BQ',
    alpha3: 'BES',
  },
  {
    id: 185,
    name: 'Saint Barthélemy',
    alpha2: 'BL',
    alpha3: 'BLM',
  },
  {
    id: 186,
    name: 'Saint Christopher & Nevis',
    alpha2: 'KN',
    alpha3: 'KNA',
  },
  {
    id: 187,
    name: 'Saint Helena',
    alpha2: 'SH',
    alpha3: 'SHN',
  },
  {
    id: 188,
    name: 'Saint Lucia',
    alpha2: 'LC',
    alpha3: 'LCA',
  },
  {
    id: 189,
    name: 'Saint Martin',
    alpha2: 'MF',
    alpha3: 'MAF',
  },
  {
    id: 190,
    name: 'Saint Pierre & Miquelon',
    alpha2: 'PM',
    alpha3: 'SPM',
  },
  {
    id: 191,
    name: 'Saint Vincent & The Grenadines',
    alpha2: 'VC',
    alpha3: 'VCT',
  },
  {
    id: 192,
    name: 'Samoa',
    alpha2: 'WS',
    alpha3: 'WSM',
  },
  {
    id: 193,
    name: 'San Marino',
    alpha2: 'SM',
    alpha3: 'SMR',
  },
  {
    id: 194,
    name: 'Sao Tome & Principe',
    alpha2: 'ST',
    alpha3: 'STP',
  },
  {
    id: 195,
    name: 'Saudi Arabia',
    alpha2: 'SA',
    alpha3: 'SAU',
  },
  {
    id: 196,
    name: 'Senegal',
    alpha2: 'SN',
    alpha3: 'SEN',
  },
  {
    id: 197,
    name: 'Serbia',
    alpha2: 'RS',
    alpha3: 'SRB',
  },
  {
    id: 198,
    name: 'Seychelles',
    alpha2: 'SC',
    alpha3: 'SYC',
  },
  {
    id: 199,
    name: 'Sierra Leone',
    alpha2: 'SL',
    alpha3: 'SLE',
  },
  {
    id: 200,
    name: 'Singapore',
    alpha2: 'SG',
    alpha3: 'SGP',
  },
  {
    id: 201,
    name: 'Sint Eustatius',
    alpha2: 'BQ',
    alpha3: 'BES',
  },
  {
    id: 202,
    name: 'Sint Maarten',
    alpha2: 'SX',
    alpha3: 'SXM',
  },
  {
    id: 203,
    name: 'Slovakia',
    alpha2: 'SK',
    alpha3: 'SVK',
  },
  {
    id: 204,
    name: 'Slovenia',
    alpha2: 'SI',
    alpha3: 'SVN',
  },
  {
    id: 205,
    name: 'Solomon Islands',
    alpha2: 'SB',
    alpha3: 'SLB',
  },
  {
    id: 206,
    name: 'Somalia',
    alpha2: 'SO',
    alpha3: 'SOM',
  },
  {
    id: 207,
    name: 'Somaliland',
    alpha2: 'SO',
    alpha3: 'SOM',
  },
  {
    id: 208,
    name: 'South Africa',
    alpha2: 'ZA',
    alpha3: 'ZAF',
  },
  {
    id: 209,
    name: 'South Georgia & The South Sandwish Islands',
    alpha2: 'GS',
    alpha3: 'SGS',
  },
  {
    id: 210,
    name: 'South Korea',
    alpha2: 'KR',
    alpha3: 'KOR',
  },
  {
    id: 211,
    name: 'South Sudan',
    alpha2: 'SS',
    alpha3: 'SSD',
  },
  {
    id: 212,
    name: 'Spain',
    alpha2: 'ES',
    alpha3: 'ESP',
  },
  {
    id: 213,
    name: 'Sri Lanka',
    alpha2: 'LK',
    alpha3: 'LKA',
  },
  {
    id: 214,
    name: 'Sudan',
    alpha2: 'SD',
    alpha3: 'SDN',
  },
  {
    id: 215,
    name: 'Suriname',
    alpha2: 'SR',
    alpha3: 'SUR',
  },
  {
    id: 216,
    name: 'Eswatini',
    alpha2: 'SZ',
    alpha3: 'SWZ',
  },
  {
    id: 217,
    name: 'Sweden',
    alpha2: 'SE',
    alpha3: 'SWE',
  },
  {
    id: 218,
    name: 'Switzerland',
    alpha2: 'CH',
    alpha3: 'CHE',
  },
  {
    id: 219,
    name: 'Syria',
    alpha2: 'SY',
    alpha3: 'SYR',
  },
  {
    id: 220,
    name: 'Taiwan',
    alpha2: 'TW',
    alpha3: 'TWN',
  },
  {
    id: 221,
    name: 'Tajikistan',
    alpha2: 'TJ',
    alpha3: 'TJK',
  },
  {
    id: 222,
    name: 'Tanzania',
    alpha2: 'TZ',
    alpha3: 'TZA',
  },
  {
    id: 223,
    name: 'Thailand',
    alpha2: 'TH',
    alpha3: 'THA',
  },
  {
    id: 224,
    name: 'Togo',
    alpha2: 'TG',
    alpha3: 'TGO',
  },
  {
    id: 225,
    name: 'Tokelau',
    alpha2: 'TK',
    alpha3: 'TKL',
  },
  {
    id: 226,
    name: 'Tonga ',
    alpha2: 'TO',
    alpha3: 'TON',
  },
  {
    id: 227,
    name: 'Trinidad & Tobago',
    alpha2: 'TT',
    alpha3: 'TTO',
  },
  {
    id: 228,
    name: 'Tunisia',
    alpha2: 'TN',
    alpha3: 'TUN',
  },
  {
    id: 229,
    name: 'Turkey',
    alpha2: 'TR',
    alpha3: 'TUR',
  },
  {
    id: 230,
    name: 'Turkmenistan',
    alpha2: 'TM',
    alpha3: 'TKM',
  },
  {
    id: 231,
    name: 'Turks & Caicos Islands',
    alpha2: 'TC',
    alpha3: 'TCA',
  },
  {
    id: 232,
    name: 'Tuvalu',
    alpha2: 'TV',
    alpha3: 'TUV',
  },
  {
    id: 233,
    name: 'Uganda',
    alpha2: 'UG',
    alpha3: 'UGA',
  },
  {
    id: 234,
    name: 'Ukraine',
    alpha2: 'UA',
    alpha3: 'UKR',
  },
  {
    id: 235,
    name: 'United Arab Emirates',
    alpha2: 'AE',
    alpha3: 'ARE',
  },
  {
    id: 236,
    name: 'United Kingdom',
    alpha2: 'GB',
    alpha3: 'GBR',
  },
  {
    id: 237,
    name: 'United States',
    alpha2: 'US',
    alpha3: 'USA',
  },
  {
    id: 238,
    name: 'United States Virgin Islands',
    alpha2: 'VI',
    alpha3: 'VIR',
  },
  {
    id: 239,
    name: 'Uruguay',
    alpha2: 'UY',
    alpha3: 'URY',
  },
  {
    id: 240,
    name: 'Uzbekistan',
    alpha2: 'UZ',
    alpha3: 'UZB',
  },
  {
    id: 241,
    name: 'Vanuatu',
    alpha2: 'VU',
    alpha3: 'VUT',
  },
  {
    id: 242,
    name: 'Venezuela',
    alpha2: 'VE',
    alpha3: 'VEN',
  },
  {
    id: 243,
    name: 'Vietnam',
    alpha2: 'VN',
    alpha3: 'VNM',
  },
  {
    id: 244,
    name: 'Wallis & Futuna',
    alpha2: 'WF',
    alpha3: 'WLF',
  },
  {
    id: 245,
    name: 'Western Sahara',
    alpha2: 'EH',
    alpha3: 'ESH',
  },
  {
    id: 246,
    name: 'Yemen',
    alpha2: 'YE',
    alpha3: 'YEM',
  },
  {
    id: 247,
    name: 'Zambia',
    alpha2: 'ZM',
    alpha3: 'ZMB',
  },
  {
    id: 248,
    name: 'Zimbabwe',
    alpha2: 'ZW',
    alpha3: 'ZWE',
  },
  {
    id: 249,
    name: 'Saint Kitts',
    alpha2: 'KN',
    alpha3: 'KNA',
  },
  {
    id: 250,
    name: 'Kosovo',
    alpha2: 'XK',
    alpha3: 'XKX',
  },
]

/**
 * Map of PandaTS countries using the ID as the key.
 */
export const PandaTsCountriesById: Map<PandaTsCountryId, PandaTsCountry> = new Map(
  PandaTsCountries.map((country) => [country.id, country])
)

/**
 * Map of PandaTS countries using the country's ISO-3166-1 alpha-2 code as the key.
 */
export const PandaTsCountriesByAlpha2: Map<PandaTsCountryAlpha2, PandaTsCountry> = new Map(
  PandaTsCountries.map((country) => [country.alpha2, country])
)

/**
 * Map of PandaTS countries using the country's ISO-3166-1 alpha-3 code as the key.
 */
export const PandaTsCountriesByAlpha3: Map<PandaTsCountryAlpha3, PandaTsCountry> = new Map(
  PandaTsCountries.map((country) => [country.alpha3, country])
)

/**
 * Map of PandaTS countries using the country's name as the key.
 */
export const PandaTsCountriesByName: Map<PandaTsCountryName, PandaTsCountry> = new Map(
  PandaTsCountries.map((country) => [country.name, country])
)

/**
 * Freeze all exported objects to prevent accidental modification.
 */
Object.freeze(PandaTsCountries)
Object.freeze(PandaTsCountriesById)
Object.freeze(PandaTsCountriesByAlpha2)
Object.freeze(PandaTsCountriesByAlpha3)
Object.freeze(PandaTsCountriesByName)
